@import url('https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap');

html {
  scrollbar-gutter: stable;
  overflow-y: auto;
}

.App {
  font-family: 'Readex Pro', sans-serif;
  color: rgba(9,9,121,1); 
  display: flex;
  flex-direction: column;
  text-align: left;
}

a:-webkit-any-link {
  color: rgba(9,9,121,1);
}

a:-webkit-any-link:visited{
  color: rgba(9,9,121,1);
}

p {
  margin: 0;
}

#navbar {
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
}

.header {
  padding: 80px 0;
  background-image: linear-gradient(90deg, rgb(30, 50, 130) 0%, rgb(60, 200, 240) 35%, rgb(86,226,215) 64%, rgb(30, 50, 130) 100%);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 60px;
}

span.line {
  display: inline-block;
}

.subtitle {
  padding: 0 80px;
  font-weight: 400;
  font-size: 48px;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  text-align: left;
  width: 50%;
  flex-direction: column;
}

.logo {
  display: flex;
  flex-direction: column;
  flex:1;
  align-items: center;
}

.App-logo {
  pointer-events: none;
  width: 50%;
  object-fit:contain;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 0 80px;
}

.information {
  margin: 80px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.information .panel {
  display: flex;
  background-color: rgba(97,218,251, 0.3);
  border-radius: 12px;
}

.information .panel 
.button {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  text-decoration: none;
}

.information .panel 
.button .button-content {
  padding: 40px 20px;
  margin: 0;
  display: flex;
  flex-direction: row;
}
        
.information .panel 
.button .button-content 
.website-image {
  height: 100%;
  max-width: 40%;
  align-self: center;
  margin: 0 7% 0 5%;
}

.information .panel 
.button .button-content 
.website-image .img { 
  object-fit: contain;
  height: inherit;
  width: 100%;
}
        

.information .panel 
.button .button-content 
.website-image .button-text {
  margin: 0 0 10px 0;
}

.panel:hover {
  background-color: rgba(97,218,251, 0.6);
}

.App-link {
  color: #61dafb;
}

.footer {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .header {
    background-image: linear-gradient(135deg, rgb(86,226,215) 20%, rgb(46,159,183) 40%, rgb(30, 50, 130) 90%);
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    padding: 80px 0;
  }

  .subtitle {
    text-align: center;
    padding: 0;
    font-size: 20px;
    width: 100%;
  }

  .content {
    padding: 0 20px;
    min-height: unset;
    padding-top: 40px;
  }

  .information{
    height: unset;
    margin: 0;
  }

  .information .panel {
    margin-bottom: 20px;
  }

  .information .panel .button .button-content {
    flex-direction: column;
  }

  .information .panel .button .button-content .website-image {
    margin: 0 0 20px 0;
  }

}