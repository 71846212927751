:root {
    --light-color: #ffffff;
    --gradient-color: linear-gradient(90deg, rgb(25,99,160) 50%, rgb(86,226,215) 80%);
    --hover-links-background-color: rgb(86,226,215);
}

.content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    background-color: var(--light-color);
    overflow-y: visible;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.versait {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    margin-left: 1rem;
}

.textLogo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    width: 70%;
}

.centerLogo {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.logo img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

ul {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
}

li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
}

.links a {
    padding: 0 20px 0 20px;
    text-decoration: none;
    font-weight: bold;
}

.links a:hover {
    color: var(--hover-links-background-color);
}

.language {
    justify-self: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.languageSelector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
}

.hamburger {
    display: none;
    justify-self: flex-end;
    margin: auto 0;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #101010;
}

button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

@media only screen and (max-width: 769px) {
    .logo svg g path, .logo svg g { display: none; }
    .logo svg { visibility: hidden; width: 0; height: 0;}
    .logo { -webkit-box-flex: 0; -ms-flex: 0; flex: 0; }

    .textLogo {
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
    }

    .versait g path {
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
    }

    .links {
        position: fixed;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: lightblue;
        right: 0;
        left:0;
        top: 60px;
        padding: 1.25rem 2.5rem 2.5rem 2.5rem;
        min-height: 100%;
        margin: unset;
    }

    .link {
        padding: 1.25rem 1.5rem 1.5rem 2.5rem;
        border-bottom: 2px solid rgba(9,9,121,1);
    }

    .hamburger {
        display: block;
        cursor: pointer;
        margin-right:20px;
    }

    .language {
        padding: 1.25rem 1.5rem 1.5rem 2.5rem;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}