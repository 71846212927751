input {
    all: unset;
}

textarea {
    all: unset;
    height: 100px;
}

.contact-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    align-content: center;
    padding: 40px;
    text-align: left;
    margin-top: 60px;
}

.contact-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    padding-left: 10%;
}

.contact-text a {
    font-weight: bolder;
    text-decoration: none;
}

.contact-form {
    display: flex;
    flex: 1;
    background-color:white;
    padding: 80px;
    border-radius: 12px;
    box-shadow: 0 0 5px lightgray;
    flex-direction: column;
}

.contact-form h1 {
    white-space: nowrap;
}

.contact-form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.contact-input-field {
    border: 2px solid rgb(230, 230, 230);
    background-color: rgb(230, 230, 230);
    border-radius: 12px;
    padding: 10px;
}

.contact-input-field:focus{
    border: 2px solid rgb(9,9,121);
}

.submit-btn {
    background-color: rgb(86,226,215);
    box-shadow: 5px 5px 10px lightgray;
    text-align: center;
    padding: 20px 40px;
    border-radius: 12px;
    width:fit-content;
}

.submit-btn:hover {
    background-color: rgb(82, 210, 199);
    cursor: pointer;
}

.submit-btn:active {
    box-shadow: unset;
    transition: box-shadow 0.5s;
}

@media only screen and (max-width: 768px) {
    .contact-content {
        flex-direction: column;
        padding: 10px 30px 40px 30px;
    }

    .contact-text {
        white-space: normal;
        padding: 0;
    }
    
    .contact-form {
        padding: 30px;
    }

    .contact-form h1 {
        margin-top: 0;
        white-space: normal;
    }
}