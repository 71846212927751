.about-content {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 120px 0 80px 0;
}

.about-card {
    display: flex;
    flex-direction: column;
    background-color: rgba(97,218,251, 0.6);
    padding: 40px;
    border-radius: 12px;
}

.about-info {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 50%;
    margin-bottom: 80px;
}

.portret-card {
    display:flex;
    flex-direction: column;
    background-color: rgba(97,218,251, 0.6);
    padding: 30px;
    border-radius: 12px;
    margin: 0 0 20px 20px;
    text-align: center;
}

.portret-card img {
    max-width: 142px;
    object-fit: contain;
    margin-bottom: 10px;
    align-self: center;
}
.portret-card .information {
    margin: 0;
    justify-content: unset;
}

.portret-card .information .question {
    margin-bottom: 20px;
}

.portret-card .information a{
    text-decoration:none;
    color: rgba(9,9,121,1);
}

@media only screen and (max-width: 767px) {
    .about-content {
        flex-direction: column;
        justify-content: unset;
        flex-wrap: wrap;
        margin: 80px 30px 10px 30px;
        gap: 10px;
        align-items: unset;
    }

    .about-info {
        width: 100%;
        margin-bottom: 0;
    }

    .about-card {
        width: unset;
    }

    .portret-card {
        display: flex;
        flex-direction: row;
        padding: 20px;
        margin: 0;
    }

    .portret-card .information {
        flex:1;
        width: unset;
    }

    .portret-card img {
        max-width:calc(40% - 15px);
        flex:1;
        margin: 0 10px 0 0;
        align-self: unset;
    }
}