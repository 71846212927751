.footer_body {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    padding: 40px 40px 10px 40px;
    width: 100%;
}

.footer_spacing {
    display: flex;
    gap: 40px;
    justify-content: center;
    flex-direction: row;
}

.footer_spacing a {
    margin-bottom: 20px;
    color: rgba(9,9,121,1);
    text-decoration: none;
}

.footer_spacing .fontAwesomeIcon{
    font-size: 32px;
}

.footer_terms {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px;
    margin-top: 20px;
    gap: 5px;
}

.footer_google {
    display: flex;
    justify-content: center;
    font-size: 10px;
}

@media only screen and (max-width: 426px) {
    .footer_body {
        padding: 40px 0 10px 0;
    }

    .footer_spacing {
        gap: 0;
        justify-content: space-evenly;
    }

    .footer_spacing p {
        text-align: center;
    }

    .footer_terms {
        font-size: 10px;
    }

    .footer_google p {
        text-align: center;
        font-size: 8px;
    }
}