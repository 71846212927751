.terms-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 80px;
    text-align: left;
    margin-top: 60px;
}

.terms-content button {
    background-color: rgb(86,226,215);
    box-shadow: 5px 5px 10px lightgray;
    text-align: center;
    padding: 20px 40px;
    border-radius: 12px;
    width:fit-content;
}

.terms-content button:hover {
    background-color: rgb(82, 210, 199);
    cursor: pointer;
}

.terms-content button:active {
    box-shadow: unset;
    transition: box-shadow 0.5s;
}

.terms-content h1 {
    margin-bottom: 0;
}

.terms-content h2 {
    margin-bottom: 0;
}

.terms-artikels {
    margin:0;
    padding:0;
}

.terms-artikels li{
    justify-content: flex-start;
    list-style: unset;
}

@media only screen and (max-width: 769px) {
    .terms-content {
        padding: 10px 30px 80px 30px;
    }

    .terms-content button {
        align-self: center;
    }
}