.App {
    min-height: 100vh;
}

.services-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px;
    flex:1;
    margin-top: 60px;

    .title {
        font-weight: bolder;
        text-align: center;
    }

    .cards {
        display:flex;
        flex-direction: row;
        gap: 40px;
    }

    .card {
        display: flex;
        background-color: rgba(97,218,251, 0.6);
        border-radius: 12px;
        padding: 20px;
        justify-content: left;
        flex-direction: column;
        align-items: center;
        align-content: flex-start;
        flex:1;

        .image {
            max-height: 10vh;
            max-width:calc(50% - 10px);
            display: flex;
            object-fit: contain;
            margin-bottom: 20px;
        }

        .text {
            align-self: flex-start;
        }

    }

}

@media only screen and (max-width: 1300px){
    .services-content .cards{
        flex-wrap: wrap;
        gap: 20px;

        .card {
            flex-basis: calc(40% - 40px);
        }
    }
}

@media only screen and (max-width: 768px) {
    .services-content {
        padding: 0 30px 30px 30px;
    }

    .services-content .title h1 {
        margin-top: 0;
    }

    .services-content .cards{
        flex-direction: column;
        gap: 20px;
    }
}
